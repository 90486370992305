"use client";

import type Branch from "branch-sdk";
import type { ComponentProps } from "react";
import { createContext } from "react";

import { useRequestMe } from "../../data";
import { URL_ACCESS, URL_SELF, URL_TRIVIA_IMAGE } from "../constants";

export type BranchLinkCreator = {
  campaign: (linkProps: BranchLinkById) => Promise<string>;
  collection: (linkProps: BranchLinkById) => Promise<string>;
  community: (linkProps: BranchLinkByIdAndName) => Promise<string>;
  dailyQuote: (linkProps: BranchLinkByIdAndUrl) => Promise<string>;
  general: (linkProps?: Branch.DeepLinkData) => Promise<string>;
  link: (linkProps: Branch.DeepLinkData) => Promise<string>;
  prayer: (linkProps: BranchLinkById) => Promise<string>;
  radioStation: (linkProps: BranchLinkById) => Promise<string>;
  trivia: (linkProps: BranchLinkByDate) => Promise<string>;
};

export const BranchContext = createContext<BranchLinkCreator>({
  campaign: () => Promise.resolve(""),
  collection: () => Promise.resolve(""),
  community: () => Promise.resolve(""),
  dailyQuote: () => Promise.resolve(""),
  general: () => Promise.resolve(""),
  link: () => Promise.resolve(""),
  prayer: () => Promise.resolve(""),
  radioStation: () => Promise.resolve(""),
  trivia: () => Promise.resolve(""),
});

export type BranchLinkById = Branch.DeepLinkData & {
  id: number;
};

export type BranchLinkByIdAndName = BranchLinkById & {
  name: string;
};

export type BranchLinkByIdAndUrl = BranchLinkById & {
  url?: string;
};

export type BranchLinkByDate = Branch.DeepLinkData & {
  date: string | Date;
};

export const BranchProvider = ({
  children,
  ...props
}: Omit<ComponentProps<typeof BranchContext.Provider>, "value">) => {
  const { query: user } = useRequestMe();

  const link = async (linkProps: Branch.DeepLinkData) => {
    const response = await fetch("https://api2.branch.io/v1/url", {
      method: "POST",
      body: JSON.stringify({
        branch_key: process.env.NEXT_PUBLIC_BRANCH_KEY,
        ...linkProps,
      }),
    });
    const body = await response.json();
    if (response.ok) return body.url;
    throw body.error.message;
  };

  const general = async (linkProps?: Branch.DeepLinkData) => {
    const code = user?.data?.referral_code ?? "no-referral-code";
    const path = `${URL_ACCESS}${user?.data?.referral_code ? `?referrer=${user.data.referral_code}` : ""}`;
    return link({
      campaign: code,
      channel: "Referral",
      data: {
        $canonical_identifier: `share-${code}`,
        $deeplink_path: path,
        $fallback_url: path,
      },
      feature: "Referral",
      ...linkProps,
    });
  };

  const dailyQuote = async ({
    id,
    url,
    data,
    ...linkProps
  }: BranchLinkByIdAndUrl) => {
    const path = url ?? "https://hallow.com/daily-quote/";
    return link({
      campaign: "Daily Quote",
      channel: "Referral",
      data: {
        $canonical_identifier: `${user.data?.id ?? null}-daily-quote-${id}`,
        $deeplink_path: path,
        $fallback_url: path,
        ...data,
      },
      feature: "Referral",
      ...linkProps,
    });
  };

  const trivia = async ({ date, data, ...linkProps }: BranchLinkByDate) => {
    return link({
      campaign: `trivia-${new Date(date).toDateString()}`,
      channel: "Referral",
      data: {
        $deeplink_path: "/trivia",
        $fallback_url: `${URL_SELF}/trivia`,
        $og_image_url: URL_TRIVIA_IMAGE,
        ...data,
      },
      feature: "Trivia",
      ...linkProps,
    });
  };

  const prayer = async ({ id, data, ...linkProps }: BranchLinkById) => {
    const path = `/prayers/${id}?is_shared=true`;
    return link({
      feature: "Prayer",
      campaign: `Prayer - ${id}`,
      data: {
        $canonical_identifier: `${user?.data?.id}-prayer-${id}`,
        $deeplink_path: path,
        $fallback_url: `${URL_SELF}${path}`,
        ...data,
      },
      ...linkProps,
    });
  };

  const community = async ({
    id,
    name,
    data,
    ...linkProps
  }: BranchLinkByIdAndName) => {
    const path = `/community/${id}`;
    return link({
      feature: "Community",
      campaign: `Community - ${name}`,
      data: {
        $canonical_identifier: `${user?.data?.id}-community-${id}`,
        $deeplink_path: path,
        $fallback_url: `${URL_SELF}${path}`,
        ...data,
      },
      ...linkProps,
    });
  };

  const collection = async ({ id, data, ...linkProps }: BranchLinkById) => {
    const path = `/collections/${id}?is_shared=true`;
    return link({
      feature: "Collection",
      campaign: `Collection - ${id}`,
      data: {
        $canonical_identifier: `${user?.data?.id}-collection-${id}`,
        $deeplink_path: path,
        $fallback_url: `${URL_SELF}${path}`,
        ...data,
      },
      ...linkProps,
    });
  };

  const radioStation = async ({ id, data, ...linkProps }: BranchLinkById) => {
    const path = `/radio-stations/${id}`;
    return link({
      feature: "Radio Station",
      campaign: `Radio Station - ${id}`,
      data: {
        $canonical_identifier: `${user?.data?.id}-radio-stations-${id}`,
        $deeplink_path: path,
        $fallback_url: `${URL_SELF}${path}`,
        ...data,
      },
      ...linkProps,
    });
  };

  const campaign = async ({ id, data, ...linkProps }: BranchLinkById) => {
    const path = `/campaigns/${id}`;
    return link({
      feature: "Campaign",
      campaign: `Campaign - ${id}`,
      data: {
        $canonical_identifier: `${user?.data?.id}-campaign-${id}`,
        $deeplink_path: path,
        $fallback_url: `${URL_SELF}${path}`,
        ...data,
      },
      ...linkProps,
    });
  };

  return (
    <BranchContext.Provider
      value={{
        campaign,
        collection,
        community,
        dailyQuote,
        general,
        link,
        prayer,
        radioStation,
        trivia,
      }}
      {...props}
    >
      {children}
    </BranchContext.Provider>
  );
};
