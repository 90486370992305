import { z } from "zod";

import { communitySchema } from "../communitySchema";

export const communitiesChildrenSchema = z.object({
  all_children_community_ids: z.number().array(),
  total: z.number(),
  results: z.array(communitySchema),
});

export type CommunitiesChildren = z.infer<typeof communitiesChildrenSchema>;
