import { z } from "zod";

import type { CookieKey } from "./types";
import type {
  DeleteCookieProps,
  GetCookieProps,
  SetCookieProps,
} from "./utils";
import { deleteCookie, getCookie, setCookie } from "./utils";

export const cookieDownloadModalCountSchema = z.string().nullable();

export type CookieDownloadModalCount = z.TypeOf<
  typeof cookieDownloadModalCountSchema
>;

export const COOKIE_KEY_DOWNLOAD_MODAL_COUNT: CookieKey =
  "download-modal-count";

export type DeleteCookieDownloadModalCountProps = Omit<
  DeleteCookieProps,
  "key"
>;

export const deleteCookieDownloadModalCount = ({
  ...props
}: DeleteCookieDownloadModalCountProps = {}) =>
  deleteCookie({ key: COOKIE_KEY_DOWNLOAD_MODAL_COUNT, ...props });

export type GetCookieDownloadModalCountProps = Omit<GetCookieProps, "key">;

export const getCookieDownloadModalCount = ({
  ...props
}: GetCookieDownloadModalCountProps = {}) =>
  getCookie({
    key: COOKIE_KEY_DOWNLOAD_MODAL_COUNT,
    schema: cookieDownloadModalCountSchema,
    ...props,
  });

export type SetCookieDownloadModalCountProps = Omit<SetCookieProps, "key">;

export const setCookieDownloadModalCount = ({
  ...props
}: SetCookieDownloadModalCountProps) =>
  setCookie({ key: COOKIE_KEY_DOWNLOAD_MODAL_COUNT, ...props });
