"use client";

import { useQueryClient } from "@tanstack/react-query";
import { useLocale } from "next-intl";

import type { UseRequestMutationProps } from "../../../../request";
import { useRequestMutation } from "../../../../request";
import { keyCommunityBroadcasts } from "./keyCommunityBroadcasts";

export type UseRequestCommunityBroadcastDeleteVariables = {
  broadcastId: number;
};

export type UseRequestCommunityBroadcastPostVariables = {
  broadcasted_to_community_ids: number[];
  content: string;
};

export type UseRequestCommunityBroadcastProps = {
  communityId: number;
  mutationDeleteProps?: UseRequestMutationProps<
    any,
    UseRequestCommunityBroadcastDeleteVariables
  >;
  mutationPostProps?: UseRequestMutationProps<
    any,
    UseRequestCommunityBroadcastPostVariables
  >;
};

export const useRequestCommunityBroadcast = ({
  communityId,
  mutationDeleteProps,
  mutationPostProps,
}: UseRequestCommunityBroadcastProps) => {
  const locale = useLocale();
  const queryClient = useQueryClient();

  return {
    mutationDelete: useRequestMutation({
      ...mutationDeleteProps,
      mutationFnRequestProps: ({ broadcastId }) => ({
        method: "DELETE",
        urlBackendPath: `/communities/${communityId}/broadcasts/${broadcastId}`,
      }),
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [keyCommunityBroadcasts({ id: communityId }), locale],
        });

        if (mutationDeleteProps?.onSuccess)
          mutationDeleteProps.onSuccess(data, variables, context);
      },
    }),
    mutationPost: useRequestMutation({
      ...mutationPostProps,
      mutationFnRequestProps: (data) => ({
        body: JSON.stringify(data),
        method: "POST",
        urlBackendPath: `/communities/${communityId}/broadcasts`,
      }),
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [keyCommunityBroadcasts({ id: communityId }), locale],
        });

        if (mutationDeleteProps?.onSuccess)
          mutationPostProps.onSuccess(data, variables, context);
      },
    }),
  };
};
