import { z } from "zod";

export const paymentMethodSchema = z.object({
  last4: z.string(),
  brand: z.string(),
  exp_year: z.number(),
  exp_month: z.number(),
});

export type PaymentMethod = z.infer<typeof paymentMethodSchema>;
