import { z } from "zod";

import { productSchema } from "./productSchema";

const introOfferSchema = z.object({
  amount: z.number(),
  locale: z.string(),
});

const promoCodeOptionSchema = z.object({
  name: z.string(),
  variables: z.any(),
});

export type PromoCodeOption = z.infer<typeof promoCodeOptionSchema>;

export const promoCodeSchema = z.object({
  id: z.number(),
  code: z.string(),
  group: z.string(),
  grants: z.array(promoCodeOptionSchema),
  rules: z.array(promoCodeOptionSchema),
});

export type PromoCode = z.infer<typeof promoCodeSchema>;

const cartErrorSchema = z.object({
  status: z.string(),
  error: z.string(),
  code: z.number(),
});

const taxSchema = z.object({
  name: z.string(),
  juris_name: z.string(),
  amount: z.number(),
});

export type Tax = z.infer<typeof taxSchema>;

export const cartSchema = z.object({
  currency: z.string(),
  discount: z.number(),
  errors: z.array(cartErrorSchema),
  intro_offer: introOfferSchema.nullable(),
  needs_postal_code: z.boolean(),
  payment_required: z.boolean(),
  pretax_total: z.number(),
  product: productSchema,
  promo_code: promoCodeSchema.nullable(),
  renewal_discount: z.number(),
  renewal_pretax_total: z.number(),
  renewal_tax: z.number(),
  renewal_taxes: taxSchema.array(),
  renewal_total: z.number(),
  subtotal: z.number(),
  supported_platforms: z.array(
    z.enum(["apple", "google", "amazon", "stripe", "paypal", "hallow"]),
  ),
  tax: z.number(),
  taxes: taxSchema.array(),
  total: z.number(),
  trial_end_date: z.string().datetime(),
});

export type Cart = z.infer<typeof cartSchema>;

type PaymentAddress = {
  city?: string;
  line1?: string;
  line2?: string;
  postal_code?: string;
  state?: string;
  country?: string;
  metadata?: string;
};

export type PaymentResult = {
  paymentMethod: string | null;
  paymentPlatform?: "stripe" | "paypal";
  name?: string;
  email?: string;
  address?: PaymentAddress;
};
