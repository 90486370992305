"use client";

import type { CommunityBroadcasts } from "../../../../lib";
import { communityBroadcastsSchema } from "../../../../lib";
import type { UseRequestInfiniteQueryProps } from "../../../../request";
import { useRequestInfiniteQuery } from "../../../../request";
import { keyCommunityBroadcasts } from "./keyCommunityBroadcasts";

export type UseRequestCommunityBroadcastsProps =
  UseRequestInfiniteQueryProps & {
    id: number;
    // type?: TBD (intentions)
  };

export const useRequestCommunityBroadcasts = ({
  id,
  ...props
}: UseRequestCommunityBroadcastsProps) => ({
  infiniteQuery: useRequestInfiniteQuery({
    getNextPageParam: (lastPage: CommunityBroadcasts) =>
      lastPage?.results[lastPage?.results?.length - 1]?.id || null,
    initialPageParam: -1,
    ...props,
    queryFnRequestProps: ({ pageParam }) => {
      const searchParams = new URLSearchParams("?per_page=10");
      if (pageParam && pageParam !== -1)
        searchParams.set("last_id", pageParam as string);

      return {
        schema: communityBroadcastsSchema,
        urlBackendPath: `/communities/${id}/broadcasts${searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ""}`,
      };
    },
    queryKey: keyCommunityBroadcasts({ id }),
  }),
});
