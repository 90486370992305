import { z } from "zod";

import type { CookieKey } from "./types";
import type {
  DeleteCookieProps,
  GetCookieProps,
  SetCookieProps,
} from "./utils";
import { deleteCookie, getCookie, setCookie } from "./utils";

export const cookieDownloadModalBlockedSchema = z.string().nullable();

export type CookieDownloadModalBlocked = z.TypeOf<
  typeof cookieDownloadModalBlockedSchema
>;

export const COOKIE_KEY_DOWNLOAD_MODAL_BLOCKED: CookieKey =
  "download-modal-blocked";

export type DeleteCookieDownloadModalBlockedProps = Omit<
  DeleteCookieProps,
  "key"
>;

export const deleteCookieDownloadModalBlocked = ({
  ...props
}: DeleteCookieDownloadModalBlockedProps = {}) =>
  deleteCookie({ key: COOKIE_KEY_DOWNLOAD_MODAL_BLOCKED, ...props });

export type GetCookieDownloadModalBlockedProps = Omit<GetCookieProps, "key">;

export const getCookieDownloadModalBlocked = ({
  ...props
}: GetCookieDownloadModalBlockedProps = {}) =>
  getCookie({
    key: COOKIE_KEY_DOWNLOAD_MODAL_BLOCKED,
    schema: cookieDownloadModalBlockedSchema,
    ...props,
  });

export type SetCookieDownloadModalBlockedProps = Omit<SetCookieProps, "key">;

export const setCookieDownloadModalBlocked = ({
  ...props
}: SetCookieDownloadModalBlockedProps) =>
  setCookie({ key: COOKIE_KEY_DOWNLOAD_MODAL_BLOCKED, ...props });
