import { z } from "zod";

import { userSchema } from "../../user";
import { communitySchema } from "../communitySchema";

export const communityBroadcastSchema = z.object({
  broadcasted_to_communities: communitySchema.partial().array(),
  broadcaster_community: communitySchema.partial().nullable().optional(),
  broadcaster_community_id: z.number().optional(),
  broadcaster_user: userSchema.nullable().optional(),
  broadcaster_user_id: z.number().optional(),
  content: z.string(),
  created_at: z.string(),
  id: z.number(),
});

export type CommunityBroadcast = z.TypeOf<typeof communityBroadcastSchema>;
