import type { BaseKeyFunction } from "../../../../lib";

export type KeyCommunityBroadcastsProps = {
  id: number;
  // type?: TBD (intentions)
};

export const keyCommunityBroadcasts: BaseKeyFunction<
  KeyCommunityBroadcastsProps
> = ({ id }) => ["community-broadcasts", id.toString()];
