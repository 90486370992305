"use client";

import { paymentMethodSchema } from "../../../../lib";
import type { UseRequestQueryProps } from "../../../../request";
import { useRequestQuery } from "../../../../request";
import { keyPaymentMethod } from "./keyPaymentMethod";

export const useRequestPaymentMethod = ({
  ...props
}: UseRequestQueryProps = {}) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: paymentMethodSchema,
      urlBackendPath: "/payments/method",
    },
    queryKey: keyPaymentMethod(),
  }),
});
