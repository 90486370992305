import { isPossiblePhoneNumber } from "libphonenumber-js";
import { z } from "zod";

export const emailAuthFlowSchema = z.object({
  email: z.string().email(),
});
export type EmailAuthFlowForm = z.infer<typeof emailAuthFlowSchema>;

export const preexistingPasswordAuthFlowSchema = z.object({
  password: z.string(),
});

export const passwordAuthFlowSchema = z.object({
  password: z
    .string()
    .min(8)
    .superRefine((val, ctx) => {
      if (/(.)\1\1\1/g.test(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "web_error_password_repetition",
        });
      }
      if (
        /abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz|0123|1234|2345|3456|4567|5678|6789|7890/i.test(
          val,
        )
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "web_error_password_sequence",
        });
      }
      if (DISALLOWED_PASSWORDS.includes(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "web_error_disallowed_password",
        });
      }
    }),
});
export type PasswordAuthFlowForm = z.infer<typeof passwordAuthFlowSchema>;

export const phoneAuthFlowSchema = z.object({
  phone: z.string().superRefine((val, ctx) => {
    if (!isPossiblePhoneNumber(val ?? "")) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "error_invalid_phone_number",
      });
    }
  }),
});

const DISALLOWED_PASSWORDS = [
  "hallowapp",
  "picture1",
  "password",
  "Million2",
  "iloveyou",
  "aaron431",
  "password1",
  "qqww1122",
  "qwertyuiop",
  "asdfghjkl",
  "987654321",
  "chatbooks",
  "123123123",
  "princess",
  "jacket025",
  "sunshine",
  "1q2w3e4r",
  "qwerty123",
  "Bangbang123",
  "jobandtalent",
  "football",
  "baseball",
  "superman",
  "samantha",
  "babygirl",
  "jordan23",
  "basketball",
  "michelle",
  "chocolate",
  "computer",
  "password123",
  "iloveyou1",
  "0987654321",
  "blink182",
  "princess1",
  "fuckyou1",
  "trustno1",
  "butterfly",
  "x4ivygA51F",
  "starwars",
  "michael1",
  "jakcgt333",
  "jennifer",
  "babygirl1",
  "sample123",
  "football1",
  "whatever",
  "alexander",
  "passw0rd",
  "11223344",
];

export const customEmailAuthSchema = (re: RegExp) =>
  z.object({ email: z.string().regex(re) });

export type PhoneAuthFlowForm = z.infer<typeof phoneAuthFlowSchema>;
